<template>
  <div>
    <v-row justify="end">
      <template>
        <div class="my-2">
          <v-btn class="btn-primary" fab small dark @click="OpenModalAdd()">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </div>
      </template>
    </v-row>

    <v-data-table
        :headers="headers"
        :items="items"
        :items-per-page="15"
        class="elevation-1"
        :loading="TableLoading"
        :disabled="TableLoading"
        loading-text="Loading... Please wait"
    >
      <template v-slot:item.accumulated_purchase_number="{ item }">
        <div class="primary--text">
          {{ Number(item.accumulated_purchase_number).toLocaleString() }}
        </div>
      </template>
      <template v-slot:item.discount="{ item }">
        <div class="primary--text">
          {{ Number(item.discount).toLocaleString() }}
        </div>
      </template>
      <template v-slot:item.bonding_fees="{ item }">
        <div class="error--text">
          {{ Number(item.bonding_fees).toLocaleString() }}
        </div>
      </template>
      <template v-slot:item.price="{ item }">
        <div class="primary--text">
          {{ Number(item.price).toLocaleString() }}
        </div>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="OpenModalEdit(item)">
          mdi-pencil
        </v-icon>
        <v-icon small @click="deleteItem(item.id)"> mdi-delete</v-icon>
      </template>
    </v-data-table>

    <!--Delete Modal-->
    <ModalAdd>
      <template @close="close">
        <v-card>
          <v-card-title>
            <span class="headline">Add Package</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form ref="form" lazy-validation>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                        label="ຊື່ແພັກເກດ*"
                        required
                        v-model="addValue.name"
                        :rules="nameRules"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12">
                    <v-text-field
                        label="ເງື່ອນໄຂ *"
                        required
                        v-model="addValue.condition"
                        :rules="conditionRules"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-select
                        required
                        v-model="selectedItem"
                        :items="listItem"
                        item-text="name"
                        item-value="name"
                        label="ເລືອກໄລຍະເວລາ *"
                        :rules="rulePer"
                    >
                    </v-select>
                  </v-col>
                  </v-row>

<!--                <v-row>-->
<!--                  <v-col cols="12">-->
<!--                    <v-label>ຟຣີສັ່ງຊື້ນ້ຳຄັ້ງທຳອິດບໍ່ ?</v-label>-->
<!--                    <v-radio-group v-model="free_first_customer" row>-->
<!--                      <v-radio label="Yes" value="yes"></v-radio>-->
<!--                      <v-radio label="No" value="no"></v-radio>-->
<!--                    </v-radio-group>-->
<!--                  </v-col>-->
<!--                  <v-col cols="12">-->
<!--                    <v-label>ຟຣີສະສົມຈຳນວນເງິນ ?</v-label>-->
<!--                    <v-radio-group v-model="accumulate_bottle" row>-->
<!--                      <v-radio-->
<!--                          label="Yes"-->
<!--                          value="yes"-->
<!--                          @change="accumulateBottle('yes')"-->
<!--                      ></v-radio>-->
<!--                      <v-radio-->
<!--                          label="No"-->
<!--                          value="no"-->
<!--                          @change="accumulateBottle('no')"-->
<!--                      ></v-radio>-->
<!--                    </v-radio-group>-->
<!--                  </v-col>-->
<!--                  <v-col cols="12" v-if="isaccumulate_bottle">-->
<!--                    <v-text-field-->
<!--                        label="ຈຳນວນເງິນສະສົມເພື່ອແລກສ່ວນຫຼຸດ*"-->
<!--                        required-->
<!--                        v-model="addValue.accumulated_purchase_number"-->
<!--                        type="number"-->
<!--                        class="input-number"-->
<!--                        :rules="bottleRules"-->
<!--                    ></v-text-field>-->
<!--                  </v-col>-->
<!--                  <v-col cols="12" v-if="isaccumulate_bottle">-->
<!--                    <v-text-field-->
<!--                        label="ສ່ວນຫຼຸດເປັນເງິນ*"-->
<!--                        required-->
<!--                        v-model="addValue.discount"-->
<!--                        type="number"-->
<!--                        class="input-number"-->
<!--                        :rules="bottleRules"-->
<!--                    ></v-text-field>-->
<!--                  </v-col>-->

<!--                  <v-col cols="12">-->
<!--                    <v-label>ໄດ້ຄະແນນສະສົມບໍ່ ?</v-label>-->
<!--                    <v-radio-group v-model="accumulate_point" row>-->
<!--                      <v-radio-->
<!--                          label="Yes"-->
<!--                          value="yes"-->
<!--                          @change="accumulatePoint('yes')"-->
<!--                      ></v-radio>-->
<!--                      <v-radio-->
<!--                          label="No"-->
<!--                          value="no"-->
<!--                          @change="accumulatePoint('no')"-->
<!--                      ></v-radio>-->
<!--                    </v-radio-group>-->
<!--                  </v-col>-->

<!--                  <v-col cols="12" v-if="isaccumulate_point">-->
<!--                    <v-text-field-->
<!--                        label="ຈຳນວນເງິນສະສົມຕໍ່ຄະແນນ *"-->
<!--                        required-->
<!--                        v-model="addValue.accumulated_purchase_point"-->
<!--                        type="number"-->
<!--                        class="input-number"-->
<!--                        :rules="pointRules"-->
<!--                    ></v-text-field>-->
<!--                  </v-col>-->
<!--                  <v-col cols="12">-->
<!--                    <v-text-field-->
<!--                        label="ຄ່າມັດຈຳຕໍ່ຕຸກ *"-->
<!--                        required-->
<!--                        v-model="addValue.bonding_fees"-->
<!--                        :rules="bondingRules"-->
<!--                        type="number"-->
<!--                        class="input-number"-->
<!--                    ></v-text-field>-->
<!--                  </v-col>-->
<!--                  <v-col cols="12">-->
<!--                    <v-text-field-->
<!--                        label="ລາຄາຕໍ່ຕຸກ *"-->
<!--                        required-->
<!--                        v-model="addValue.price"-->
<!--                        :rules="priceRules"-->
<!--                        type="number"-->
<!--                        class="input-number"-->
<!--                        @keyup.enter="Add"-->
<!--                    ></v-text-field>-->
<!--                    <p class="errors">-->
<!--                      {{ server_errors.price }}-->
<!--                    </p>-->
<!--                  </v-col>-->
<!--                </v-row>-->

              </v-form>
            </v-container>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="CloseModalAdd()">
                Close
              </v-btn>
              <v-btn
                  color="blue darken-1"
                  text
                  :loading="loading"
                  :disabled="loading"
                  @click="Add()"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card-text>
        </v-card>
      </template>
    </ModalAdd>

    <!--Edit Modal-->

    <ModalEdit>
      <template @close="close">
        <v-card>
          <v-card-title>
            <span class="headline">Update Package</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form ref="form" lazy-validation>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                        label="ຊື່ແພັກເກດ*"
                        required
                        v-model="editValue.name"
                        :rules="nameRules"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                        label="ເງື່ອນໄຂ *"
                        required
                        v-model="editValue.condition"
                        :rules="conditionRules"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-select
                        required
                        v-model="editValue.per"
                        :items="listItem"
                        item-text="name"
                        item-value="name"
                        label="ເລືອກໄລຍະເວລາ *"
                        :rules="rulePer"
                    >
                    </v-select>
                  </v-col>
                </v-row>

<!--                <v-row>-->
<!--                  <v-col cols="12">-->
<!--                    <v-label>ຟຣີສັ່ງຊື້ນ້ຳຄັ້ງທຳອິດ</v-label>-->
<!--                    <v-radio-group v-model="editValue.free_first_customer" row>-->
<!--                      <v-radio label="Yes" value="yes"></v-radio>-->
<!--                      <v-radio label="No" value="no"></v-radio>-->
<!--                    </v-radio-group>-->
<!--                  </v-col>-->
<!--                  <v-col cols="12">-->
<!--                    <v-label>ຟຣີສະສົມຈຳນວນເງິນ ?</v-label>-->
<!--                    <v-radio-group v-model="editValue.bottle_giveaway" row>-->
<!--                      <v-radio-->
<!--                          label="Yes"-->
<!--                          value="yes"-->
<!--                          @change="accumulateBottle('yes')"-->
<!--                      ></v-radio>-->
<!--                      <v-radio-->
<!--                          label="No"-->
<!--                          value="no"-->
<!--                          @change="accumulateBottle('no')"-->
<!--                      ></v-radio>-->
<!--                    </v-radio-group>-->
<!--                  </v-col>-->
<!--                  <v-col cols="12" v-if="isaccumulate_bottle">-->
<!--                    <v-text-field-->
<!--                        label="ຈຳນວນເງິນສະສົມເພື່ອແລກສ່ວນຫຼຸດ*"-->
<!--                        required-->
<!--                        v-model="editValue.accumulated_purchase_number"-->
<!--                        type="number"-->
<!--                        class="input-number"-->
<!--                        :rules="bottleRules"-->
<!--                    ></v-text-field>-->
<!--                  </v-col>-->
<!--                  <v-col cols="12" v-if="isaccumulate_bottle">-->
<!--                    <v-text-field-->
<!--                        label="ສ່ວນຫຼຸດເປັນເງິນ*"-->
<!--                        required-->
<!--                        v-model="editValue.discount"-->
<!--                        type="number"-->
<!--                        class="input-number"-->
<!--                        :rules="bottleRules"-->
<!--                    ></v-text-field>-->
<!--                  </v-col>-->

<!--                  <v-col cols="12">-->
<!--                    <v-label>ໄດ້ຄະແນນສະສົມບໍ່ ?</v-label>-->
<!--                    <v-radio-group v-model="editValue.point_giveaway" row>-->
<!--                      <v-radio-->
<!--                          label="Yes"-->
<!--                          value="yes"-->
<!--                          @change="accumulatePoint('yes')"-->
<!--                      ></v-radio>-->
<!--                      <v-radio-->
<!--                          label="No"-->
<!--                          value="no"-->
<!--                          @change="accumulatePoint('no')"-->
<!--                      ></v-radio>-->
<!--                    </v-radio-group>-->
<!--                  </v-col>-->

<!--                  <v-col cols="12" v-if="isaccumulate_point">-->
<!--                    <v-text-field-->
<!--                        label="ຈຳນວນເງິນສະສົມຕໍ່ຄະແນນ *"-->
<!--                        required-->
<!--                        v-model="editValue.accumulated_purchase_point"-->
<!--                        type="number"-->
<!--                        class="input-number"-->
<!--                        :rules="pointRules"-->
<!--                    ></v-text-field>-->
<!--                  </v-col>-->
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                        label="ຄ່າມັດຈຳຕໍ່ຕຸກ *"
                        required
                        v-model="editValue.bonding_fees"
                        :rules="bondingRules"
                        type="number"
                        class="input-number"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                        label="ລາຄາຕໍ່ຕຸກ *"
                        required
                        v-model="editValue.price"
                        :rules="priceRules"
                        type="number"
                        class="input-number"
                        @keyup.enter="Update"
                    ></v-text-field>
                    <p class="errors">
                      {{ server_errors.price }}
                    </p>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols>
                    <v-checkbox v-model="editValue.show"
                                label="ສະແດງໃນແອັບ"
                                row>
                    </v-checkbox>
                  </v-col>
                </v-row>

              </v-form>
            </v-container>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="CloseModalEdit()">
                Close
              </v-btn>
              <v-btn
                  color="blue darken-1"
                  text
                  :loading="loading"
                  :disabled="loading"
                  @click="Update()"
              >
                Update
              </v-btn>
            </v-card-actions>
          </v-card-text>
        </v-card>
      </template>
    </ModalEdit>

    <!--Delete Modal-->
    <ModalDelete>
      <template>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDelete()"
          >Cancel
          </v-btn
          >
          <v-btn
              color="blue darken-1"
              text
              :loading="loading"
              :disabled="loading"
              @click="deleteItemConfirm()"
          >OK
          </v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </template>
    </ModalDelete>
    <!-- Toast-->
  </div>
</template>

<script>
import manage from "../../mixins/package/manage";

export default {
  mixins: [manage],
};
</script>

<style>
@import "../../../public/scss/main.scss";
</style>
