//import Validate from "@/plugins/Validation/Branch/branch"
export default {
    data() {
        return {
            loading: false,
            TableLoading: false,
            deleteId: '',
            server_errors: {
                condition: '',
                price: ''
            },

            listItem: [
                {
                    id: 1,
                    name: "weeks"
                },
                {
                    id: 2,
                    name: "monthly"
                }
            ],
            selectedItem: '',
            addValue: {},
            editValue: {},
            items: [],
            headers: [
                {
                    text: "Package",
                    align: "start",
                    sortable: false,
                    value: "name",
                },
                {text: "ເງື່ອນໄຂການສັ່ງ", value: "condition", sortable: false},
                {text: "ໄລຍະເວລາ", value: "per", sortable: false},
                {text: "ລາຄາ", value: "price", sortable: false},
                {text: "ມັດຈຳ", value: "bonding_fees", sortable: false},
                {text: "ສະສົມຄະແນນ", value: "point_giveaway", sortable: false},
                {text: "ສະສົມຄະແນນ", value: "point_giveaway", sortable: false},
                {text: "ຟຣີຄັ້ງທຳອິດ", value: "free_first_customer", sortable: false,width: "120px"},
                {text: "ສະແດງໃນແອັບ", value: "show", sortable: false,width: "120px"},
                {text: "Actions", value: "actions", sortable: false},
            ],
            rulePer: [(v) => !!v || 'ກາລຸນາເລືອກໄລຍະເວລາ',],
            nameRules: [
                v => !!v || 'ກາລຸນາປ້ອນຊື່ແພັກເກດ',
                v => (v && v.length >= 2) || 'Name must be less than 2 characters',
            ],
            conditionRules: [
                v => !!v || 'ກາລຸນາໃສ່ເງືອນໄຂ',
                v => (v && v.length >= 2) || 'Name must be less than 2 characters',
            ],
            bondingRules: [
                (v) => !!v || "ກາລຸນາປ້ອນຄ່າມັນຈຳ",
                // v => (v && v.length >= 1 && v.length <= 7) || 'Price must be  1 - 6 numbers',
            ],
            priceRules: [
                v => !!v || 'ກາລຸນາປ້ອນລາຄາຕໍ່ຕຸກ',
                // v => (v && v.length >= 1 && v.length <= 7) || 'Price must be less than 2 characters',
            ],
            bottleRules: [
                v => !!v || 'ກາລຸນາໃສ່ຈຳນວນຕຸກນ້ຳຂັ້ນຕ່ຳ',
                // v => (v && v.length >= 1 && v.length <= 7) || 'Price must be less than 2 characters',
            ],
            pointRules: [
                v => !!v || 'ກາລຸນາໃສ່ຈຳນວນຄະແນນ',
                // v => (v && v.length >= 1 && v.length <= 7) || 'Price must be less than 2 characters',
            ],

            toast: {
                value: true,
                color: 'success',
                msg: 'Success'
            },
            toast_error: {
                value: true,
                color: 'error',
                msg: 'Something when wrong!'
            }
        }
    },
    methods: {
        OpenModalAdd() {
            this.$store.commit("modalAdd_State", true);
        },
        Add() {
            if (this.$refs.form.validate() == true) {
                this.Submit();
            }
        },
        Submit() {
            this.loading = true;
            this.addValue.per = this.selectedItem;
            // this.addValue.accumulated_purchase_number = this.accumulated_purchase_number;
            this.$admin.post('add-package', this.addValue)
                .then(res => {
                    if (res.data.success == true) {
                        setTimeout(() => {
                            this.loading = false;
                            this.CloseModalAdd();
                            this.addValue = {};
                            // this.getData();
                            this.reset();
                            this.$store.commit("Toast_State", this.toast);
                        }, 1000);
                    }
                }).catch(error => {
                this.loading = false;
                this.$store.commit("Toast_State", this.toast_error);
                this.getData();
                if (error.response.status == 422) {
                    let obj = error.response.data.errors;
                    for (let [key, addValue] of Object.entries(obj)) {
                        this.server_errors[key] = addValue[0];
                    }
                }
            });
        },
        CloseModalAdd() {
            this.branch = {};
            this.reset();
            this.getData();
            this.$store.commit("modalAdd_State", false);
        },
        OpenModalEdit(item) {
            this.editValue = item;
            this.$store.commit("modalEdit_State", true);
        },

        Update() {
            if (this.$refs.form.validate() == true) {
                this.loading = true;
                this.$admin.put('edit-package/' + this.editValue.id, this.editValue)
                    .then(res => {
                        if (res.data.success == true) {
                            setTimeout(() => {
                                this.loading = false;
                                this.CloseModalEdit();
                                this.editValue = {};
                                this.getData();
                                this.reset();
                                this.$store.commit("Toast_State", this.toast);
                            }, 300);
                        }
                    }).catch(() => {
                        this.loading = false;
                        this.$store.commit("Toast_State", this.toast_error);
                        this.getData();
                        // if(error.response.status == 422){
                        //     var obj = error.response.data.errors;
                        //     for (let [key,branch] of Object.entries(obj)){
                        //         this.server_errors[key] = branch[0];
                        //     }
                        // }
                    }
                );
            }
        },

        CloseModalEdit() {
            this.editValue = {};
            this.getData();
            this.reset();
            this.$store.commit("modalEdit_State", false);
        },

        getData() {
            this.TableLoading = true;
            this.$admin.get('list-packages').then(res => {
                setTimeout(() => {
                    this.items = res.data.listPackages;
                    this.TableLoading = false;
                }, 100);
            }).catch(() => {
                this.TableLoading = false;
                this.$store.commit("Toast_State", this.toast_error);
            });
        },
        closeDelete() {
            this.$store.commit("modalDelete_State", false);
        },
        deleteItem(id) {
            this.deleteId = id;
            this.$store.commit("modalDelete_State", true);
        },
        deleteItemConfirm() {
            this.loading = true;
            this.$admin.delete('delete-package/' + this.deleteId).then(res => {
                if (res.data.success == true) {
                    setTimeout(() => {
                        this.getData();
                        this.$store.commit("modalDelete_State", false);
                        this.loading = false;
                        this.$store.commit("Toast_State", this.toast);
                    }, 1000);
                }
            }).catch(() => {
                this.getData();
                this.$store.commit("modalDelete_State", false);
                this.loading = true;
                this.$store.commit("Toast_State", this.toast_error);
            })
        },
        reset() {
            this.$refs.form.reset();
        },
        accumulateBottle(value) {
            if (value == "yes") {
                return this.isaccumulate_bottle = true;
            } else if (value == "no") {
                this.addValue.accumulated_purchase_number = 0;
                this.editValue.accumulated_purchase_number = 0;
                return this.isaccumulate_bottle = false;

            }
        },
        accumulatePoint(value) {
            if (value == "yes") {
                return this.isaccumulate_point = true;
            } else if (value == "no") {
                this.addValue.accumulated_purchase_point = 0;
                this.editValue.accumulated_purchase_point = 0;
                return this.isaccumulate_point = false;

            }
        }
    },
    watch: {
        'addValue.condition': function () {
            this.server_errors.condition = '';
        },
        'addValue.price': function () {
            this.server_errors.price = '';
        },
        'editValue.condition': function () {
            this.server_errors.condition = '';
        },
        'editValue.price': function () {
            this.server_errors.price = '';
        },
    },
    created() {
        this.getData();
    },
}
